<script setup>
const { client } = usePrismic();
const { locale, localeProperties } = useI18n();
const { data: document } = await useAsyncData(`home_page-${locale.value}`, async () => {
  const document = await client.getSingle("home_page", {
    lang: localeProperties.value.iso,
  });
  if (!document) {
    throw createError({ statusCode: 404, message: "Page not found" });
  }
  return document;
});

useHead({
  title: document.value?.data.meta_title,
  meta: [
    {
      name: "description",
      content: document.value?.data.meta_description,
    },
  ],
});
</script>
<template>
  <PageHome v-if="document" :document="document" />
</template>
